<template>
  <div>
    <!---
        You can use the `control` object - basically, it's main data of your custom control field
        Because I had 2 more specific config data (isChecked & switchColor)
        So I added 2 more fields below to config it.
        --->
    <div :class="styles.FORM.FORM_GROUP">
      <label>
        Is Selfie?
        <input v-model="control.isSelfie" type="checkbox" />
      </label>
    </div>

    <div :class="styles.FORM.FORM_GROUP">
      <label> Version Id </label>
      <input v-model="control.versionId" type="text" :class="styles.FORM.FORM_CONTROL" />
    </div>

    <div :class="styles.FORM.FORM_GROUP">
      <label> Accept Type </label>
      <input v-model="control.accept" type="text" :class="styles.FORM.FORM_CONTROL" />
    </div>
  </div>
</template>

<script>
import { BaseControlConfigSkeleton } from 'v-form-builder'

export default {
  name: 'ImageConfigView',
  extends: BaseControlConfigSkeleton,
}
</script>
